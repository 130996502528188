import * as React from 'react';
import lottie from 'lottie-web';
import styled from 'styled-components';

type AnimationType = {
	width: number;
	height: number;
	animation: {
		assets: unknown[];
		ddd: number;
		fr: number;
		h: number;
		ip: number;
		layers: unknown[];
		markers: unknown[];
		nm: string;
		op: number;
		v: string;
		w: number;
	};
};

type StyledTypes = {
	width: number;
	height: number;
};

const AnimationBox = styled.div<StyledTypes>`
	width: ${(props) => `${props.width}px`};
	height: ${(props) => `${props.height}px`};
	margin: 0 auto;
`;

const LottieAnimationLocalFile = ({ animation, height, width }: AnimationType) => {
	const animationContainer = React.createRef<HTMLDivElement>();

	React.useEffect(() => {
		lottie.loadAnimation({
			container: animationContainer.current,
			animationData: animation,
		});
	}, []);

	return <AnimationBox ref={animationContainer} width={width} height={height} />;
};

export default LottieAnimationLocalFile;
