import * as React from 'react';

import {
	CookieBannerEntityResponse,
	FooterEntityResponse,
	HeaderEntityResponse,
	MenuEntityResponse,
} from '@schema';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import animation from '../../animations/103901-404-error-orange.json';
import MainLayout from '../../layouts/main-layout';
import LottieAnimationLocalFile from '../../components/LottieAnimationLocalFile';

interface NotFoundPageProps {
	pageContext: {
		menu?: MenuEntityResponse;
		header?: HeaderEntityResponse;
		footer?: FooterEntityResponse;
		cookieBanner?: CookieBannerEntityResponse;
		theme: string;
	};
}

const NotFoundPage: React.FC<NotFoundPageProps> = (props: NotFoundPageProps) => {
	const { menu, header, footer, cookieBanner, theme } = props.pageContext;

	return (
		<MainLayout
			menu={menu}
			header={header}
			footer={footer}
			cookieBanner={cookieBanner}
			overflow="hidden"
			theme={theme}
		>
			{typeof window !== 'undefined' && (
				<LottieAnimationLocalFile
					animation={animation}
					width={window.innerWidth}
					height={window.innerHeight * 0.8}
				/>
			)}
		</MainLayout>
	);
};
export default NotFoundPage;
